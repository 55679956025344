<template>
  <header class="bg-white md:fixed md:left-0 md:top-0 md:z-10 md:w-full">
    <div class="mx-auto max-w-6xl p-6 md:flex md:items-center md:p-0 md:px-6">
      <div class="flex items-center">
        <h1
          :style="{ backgroundImage: `url(${logoUrl})` }"
          class="bg-contain bg-no-repeat px-5 py-3 md:mb-0 md:mr-5 md:bg-center md:p-5"
        >
          <span class="invisible">VolgPlan</span>
        </h1>

        <a
          class="ml-auto cursor-pointer md:hidden"
          @click="showMenu = !showMenu"
        >
          <x-mark-icon v-if="showMenu" class="w-7" />
          <bars3-icon v-else class="w-7" />
        </a>
      </div>

      <div
        class="mt-5 md:mt-0 md:flex md:w-full"
        :class="{ block: showMenu, hidden: !showMenu }"
      >
        <ul class="md:flex">
          <li>
            <router-link :to="{ name: 'Groups' }">Groepen</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Programs' }">Lesmethoden</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Locations' }">Scholen</router-link>
          </li>
        </ul>

        <div class="ml-auto items-center md:flex">
          <p>
            <select
              v-if="locationCount > 1"
              v-model="currentLocationId"
              class="appearance-none border-0 bg-transparent"
            >
              <option
                v-for="role in me.roles"
                :key="role.id"
                :value="role.location.id"
              >
                {{ role.location.name }}
              </option>
            </select>
          </p>

          <ul class="md:flex">
            <li><a :href="logoutUrl">Uitloggen</a></li>
          </ul>
        </div>
      </div>
    </div>

    <header-back-bar :title="backBarTitle" @submit:form="emit('submit:form')" />
  </header>
</template>

<script setup lang="ts">
  import { ref, watch, PropType } from 'vue'
  import { useRouter } from 'vue-router'

  import HeaderBackBar from './HeaderBackBar.vue'
  import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/solid'

  import logoUrl from '../assets/logo.svg'

  const props = defineProps({
    backBarTitle: {
      type: String as PropType<null | string>,
      default: () => null,
    },

    currentLocation: {
      type: Object as PropType<TenantLocation>,
      required: true,
    },

    locationCount: {
      type: Number,
      required: true,
    },

    me: {
      type: Object as PropType<User>,
      required: true,
    },
  })

  const emit = defineEmits(['set:currentLocation', 'submit:form'])
  const currentLocationId = ref(props.currentLocation.id)
  const logoutUrl = import.meta.env.VITE_APP_LOGOUT_URL

  const showMenu = ref(false)

  const router = useRouter()
  watch(router.currentRoute, (newRoute, oldRoute) => {
    if (newRoute !== oldRoute) {
      showMenu.value = false
    }
  })

  watch(currentLocationId, (newId) => {
    const role = (props.me.roles || []).filter((role) => {
      return role.location.id === newId
    })

    emit('set:currentLocation', role[0].location)
  })
</script>

<style lang="postcss" scoped>
  li a,
  select {
    @apply block px-0 py-3 text-pink-900 md:inline-block md:px-4 md:py-7;
  }

  .router-link-active {
    @apply font-bold underline decoration-orange-400 decoration-2 underline-offset-4;
  }
</style>
